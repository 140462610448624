<template>
    <v-container >
        
        <v-card elevation="0">
            <v-card-title>

                <v-spacer></v-spacer>

                <spinner v-if="loading"></spinner> 

                <v-spacer></v-spacer>

                    <v-btn
                        class="mx-2 routerLink"
                        fab
                        dark
                        small
                        color="cyan"
                        :to="{name: 'vendor.add-on.create'}"
                        >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                
            </v-card-title>

            <v-container fluid>
                <v-row 
                        v-for="(item, i) in items"
                        :key="i"
                        class="mt-2"
                    dense>

                                <v-card
                                    elevation="2"
                                    outlined
                                    color="blue-grey lighten-5"
                                    dark
                                    min-width="100%"
                                    
                                >
                                                            
                                        <v-card-title class="black--text">{{ item.name }}

                                            <v-spacer/>
                                            <!--
                                               <v-switch 
                                                :key="i"
                                                v-model="item.is_published"             
                                                :color="item.is_published?'success':'#f45525'"
                                                v-on:click="publishItem(item.id)"
                                                :append-icon="item.is_published?'mdi-earth':''"
                                                
                                                >
                                                </v-switch>
                                                
                                                    -->

                                                <v-btn 
                                                    text
                                                    class="black--text"
                                                    v-on:click="publishItem(item.id)"
                                                    
                                                >

                                                 <v-icon 
                                                        small
                                                        outline
                                                        dark
                                                        :color="item.is_published ? 'success':''"
                                                        >
                                                            mdi-earth
                                                        </v-icon>{{!item.is_published?'Publish':'Unpublish'}}

                                                </v-btn>
                                                
                                        </v-card-title>

                                        <v-card-subtitle>
                                            <p class="black--text" >Used by: {{item.packages_count}} packages</p>
                                        </v-card-subtitle>
                                        <v-card-text class="black--text" v-text="item.description"></v-card-text>

                                        <v-card-actions>

                                    
                                                <v-btn 
                                                    
                                                    text
                                                    :to="{name: 'vendor.add-on.edit', params: { id: item.id }}"
                                                    class="black--text routerLink"
                                                    >
                                                    <v-icon 
                                                        small
                                                        outline
                                                        dark
                                                        >
                                                            mdi-pencil-outline
                                                        </v-icon>Edit
                                                </v-btn>
                                            
                                        <v-btn 
                                            v-if="!item.is_published"
                                            text 
                                            v-on:click="destroy(item.id)"
                                            class="black--text"
                                            >
                                                    <v-icon 
                                                        small
                                                        >
                                                            mdi-delete
                                                        </v-icon>
                                            Delete</v-btn>


                                        <v-spacer/>
                                        <p class="black--text">{{ item.price | currency }}</p>
                                        
                                        </v-card-actions>
                                    
                                </v-card>
                </v-row>
                 
            </v-container>


        </v-card>


    </v-container>
</template>

<script>
    export default {
        middleware: ['auth','vendor','is-vendor-active'],
        data(){
            return{
                items: [],
                loading: false
            }
        },
        mounted()
        {
            this.fetchItems();
        },
        watch: {
                "$route": 'fetchItems', 
        },
        methods: {
            async fetchItems()
                {
                this.loading = true;
                let uri = '/api/vendor/addons';
                this.$http.get(uri)
                .then((response) => {
                    this.items = response.data.data,
                    this.loading = false
                }); 

            },
            deleteItem(id){

             let uri = '/api/vendor/addons/'+id;
              this.$http.delete(uri)
              .then(() => {
                this.fetchItems();
              });
              
            },            
            destroy (id) {

                    this.$toast.question('Are you sure about that?', "Confirm", {
                    timeout: 20000,
                    close: false,
                    overlay: true,
                    displayMode: 'once',
                    id: 'product',
                    zindex: 999,
                    title: 'Hey',            
                    position: 'center',
                    buttons: [
                        ['<button><b>YES</b></button>', (instance, toast) => {
                            
                            this.deleteItem(id);

                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                        }, true],
                        ['<button>NO</button>', function (instance, toast) {

                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                        }],
                    ]            
                    });            
            },
            publishItem(id)
            {
             let uri = '/api/vendor/addons/publish/'+id;
              this.$http.patch(uri)
              .then(() => {
                this.fetchItems();
              });
            },            
        }
    }
</script>

